import { render, staticRenderFns } from "./error.vue?vue&type=template&id=4fc1de5c&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=4fc1de5c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fc1de5c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CkButton: require('/home/vsts/work/1/s/components/Ck/Button/index.vue').default})
